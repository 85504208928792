<template>
  <div id="donation-site-page">
    <v-expand-transition>
        <div v-if="!finalPage" class="home-page">
            <v-form  @submit.prevent="goNxtPage">
                <v-row align="center" justify="center" class="ma-0 text-center pa-0 text-h5 text-lg-h4 text-capitalize primary--text font-weight-light text-break">{{ themeModel.heading | textCapts}}</v-row>
                <v-row align="center" justify="center" class="ma-0 text-center my-2 mb-6 text-body-2 text-lg-body-1 pa-0 primary--text font-weight-bold text-break" v-html="this.$options.filters.nl2br(themeModel.description)"></v-row>
                <v-card-title class="text-capitalize primary--text px-0 font-weight-bold justify-center justify-lg-start text-center text-break">
                    {{ paymentPage ? "Donation Details" : "Become A Member" }}
                </v-card-title>
                <template v-if="!paymentPage">
                    <v-row class="pa-0 ma-0 mobile-page" align="center" justify="start">
                        <v-col cols="12" class="ma-0 pl-0 pt-0 col-lg-3">
                            <v-btn type="button" color="secondary" large class="w-full font-weight-bold rounded-lg text-capitalize" :outlined="model.membership_type !== 'N'"
                                @click="model.membership_type = 'N';model.amount = themeModel.new_amount"
                                >
                                New Membership  |  ${{ themeModel.new_amount}}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="pl-0 pt-0 ma-0 col-lg-4">
                            <v-btn type="button" color="secondary" large class="w-full font-weight-bold rounded-lg text-capitalize" :outlined="model.membership_type !== 'R'"
                                @click="model.membership_type = 'R';model.amount = themeModel.renewal_amount">
                                Membership Renewal  |  ${{themeModel.renewal_amount}}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" class="col-lg-7 pl-0 ml-0">
                            <div class="font-weight-bold black--text text-small mb-1">Rules of Association</div>
                            <div class="text-small text-justify">{{themeModel.rules}}</div>
                            <div class="d-flex align-center my-5" :class="{'error-message': $helpers.errorMsg('agree', $v.model.agree, 'rules of association').length === 1}">
                                <v-checkbox ref="agree" hide-details="auto" class="subscribe-label pt-0 mt-0" color="secondary"
                                    on-icon="mdi-checkbox-intermediate" off-icon="mdi-checkbox-blank-outline"
                                    label="" v-model="model.agree" :false-value="0" :true-value="1"
                                ></v-checkbox>
                                <div class="font-weight-bold text-subtitle-1">
                                    I agree to the <a class="primary--text" :href="themeModel.rules_url" target="_blank" >Rules of Association</a>
                                </div>
                            </div>
                            <div v-if="$helpers.errorMsg('agree', $v.model.agree, 'rules of association').length === 1"
                                class="error--text">
                                {{ $helpers.errorMsg('agree', $v.model.agree, 'rules of association')[0] }}
                            </div>
                        </v-col>
                    </v-row>
                    <DetailsForm :valid="$v" :submitForm="submit" @updateRefs="refsObj={...refsObj, ...$event}"/>
                    <v-col cols="12" class="col-lg-7 pl-0 ml-0">
                        <vue-recaptcha @expired="onVerify" @error="onVerify" @verify="onVerify" :sitekey="recaptchaSiteKey"></vue-recaptcha>
                        <div v-if="$v.model.recaptcha.$error" class="error--text">
                            Please verify recaptcha
                        </div>
                    </v-col>
                </template>
                <template v-else>
                    <v-row align="center" justify="space-between">
                        <v-col class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                            Donation type :
                        </v-col>
                        <v-col class="col-xs-12 col-sm-12 col-md-6 col-lg-10 col-xl-10">
                            Personal
                        </v-col>
                        <v-col class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                            Name :
                        </v-col>
                        <v-col class="col-xs-12 col-sm-12 col-md-6 col-lg-10 col-xl-10">
                            {{ model.first_name || '' | textCapts}} {{ model.last_name || '' | textCapts}}
                        </v-col>
                        <v-col class="col-sm-12 col-md-6 col-lg-2 col-xl-2" v-if="model.donation_behalf">
                            On Behalf Of :
                        </v-col>
                        <v-col class="col-xs-12 col-sm-12 col-md-6 col-lg-10 col-xl-10" v-if="model.donation_behalf">
                            {{ model.donation_behalf }}
                        </v-col>
                    </v-row>
                    <PaymentForm :valid="$v" :submitForm="submit" @updateRefs="refsObj={...refsObj, ...$event}" :stripe="stripe">
                        <template slot="receiptName">
                            <Input label="Card Holder's Name*" v-if="paymentPage" :labelCls="{'error--text': $v.model.receipt_name.$dirty && !$v.model.receipt_name.required}">
                                <template v-slot:input>
                                    <v-text-field ref="receipt_name" class="rounded-lg text-capitalize" color="primary"  v-model="model.receipt_name"
                                        :error-messages="$helpers.errorMsg('name', $v.model.receipt_name, `Card Holder's Name`)"
                                        hide-details="auto" placeholder="Name On Card" label="" outlined required />
                                </template>
                            </Input>
                        </template>
                    </PaymentForm>
                </template>
                <v-row class="mt-5">
                    <v-col cols="12" class="col-lg-6 d-flex justify-center justify-lg-start align-center mobile-page">
                        <v-btn type="submit" color="secondary" block rounded large class="font-weight-bold" :loading="intentLoading">
                            <template v-slot:loader>
                                <v-progress-circular indeterminate :size="20" color="white"></v-progress-circular>
                                <span class="ml-2">Loading...</span>
                            </template>
                            {{ paymentPage ? 'Process Payment' : 'Next'}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </div>
    </v-expand-transition>
     <v-expand-x-transition>
        <Finalpage v-if="finalPage" class="transition-fast-in-fast-out v-card--reveal" @goHome="goNxtPage"/>
     </v-expand-x-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { email, required } from "vuelidate/lib/validators";
import { VueRecaptcha } from 'vue-recaptcha';

import { Input } from '@/components';

export default {
    components: {
        VueRecaptcha,
        Input,
        DetailsForm: () => import("@/pages/dashboard/component/DetailsForm"),
        PaymentForm: () => import("@/pages/dashboard/component/PaymentForm"),
        Finalpage: () => import("@/pages/dashboard/component/FinalProcess"),
    },
    name: "Home",
    validations() {
        const valid = {
            model: {
                agree: { required },
                first_name: { required },
                last_name: { required },
                address1: { required },
                phone: { required },
                suburb: { required },
                email: { email, required },
                postcode: { required },
                state: { required },
                country: { required },
                recaptcha: { required }
            }
        };
        if(this.paymentPage) {
            valid.model['receipt_name'] = { required };
        }
        return valid;
    },
    data() {
        return {
            recaptchaSiteKey: window.VUE_APP_GOOGLE_SITE_KEY,
            finalPage: false,
            submit: false,
            stripe: null,
            refsObj: {},
            themeModel: {
                heading: 'See every life alight with the love of Jesus',
                description: "Radio that can encourage, support and build up families is vital in Melbourne.\n 89.9 TheLight does just that through positive, family-friendly programs and music.",
                membership_type: 'N',
                new_amount: 5,
                renewal_amount: 10,
                rules_url: 'https://centrerefresh.positivemedia.com.au',
                rules: 'To become a legal member of Positive Media Ltd, you must read and agree to the Rules of Association. As a member, your interaction with Positive Media is legally bound by the Rules.'
            },
            paymentPage: false,
            intentLoading: false,
        };
    },
    computed: {
        ...mapGetters(["themeApiModel", "themeLoading", 'themeRecordType']),

        paymentModel: {
            get() {
                return this.$store.getters["paymentModel"];
            },
            set(newValue) {
                return this.$store.commit("updatePaymentModel", newValue);
            },
        },
        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("updateModel", newValue);
            },
        },
    },
    watch: {
        themeApiModel: function(newValue) {
            if (newValue) {
                const valueThemeModel = this.$helpers.getValueOnlyObj(newValue);
                this.themeModel = {...this.themeModel, ...valueThemeModel};
                this.model.amount = this.model.membership_type == 'N' ? this.themeModel.new_amount : this.themeModel.renewal_amount;
            }
        }
    },
    filters: {
      textCapts: function (text) {
        if (text && text.trim() !== '') {
          return text.replace('Thelight', 'TheLight');
        }
        return '';
      },
      nl2br: function (str, is_xhtml = true) {
        if (typeof str === 'undefined' || str === null) {
          return '';
        }
        const breakTag = (is_xhtml) ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
      }
    },
    created() {
        const valueThemeModel = this.$helpers.getValueOnlyObj(this.themeApiModel);
        this.themeModel = {...this.themeModel, ...valueThemeModel};
        this.model.amount = this.themeModel.new_amount;
        if (this.$helpers.getCurData("curDonate")) {
            this.finalPage = true;
        }
    },
    mounted() {
        this.stripe = window.Stripe(`${window.VUE_APP_STRIPE_PUBLISHABLE_KEY}`);
        this.refsObj = {...this.refsObj, ...this.$refs};
    },
    methods: {
        ...mapActions(["donatePaymentAmount", "StoreWthCentreSave", "createPaymentIntent"]),

        onVerify: function (response) {
            if (response) this.model.recaptcha = response;
            else this.model.recaptcha = null;
        },
        goNxtPage() {
            if(this.paymentPage) this.onProcessPayment();
            if(!this.paymentPage && !this.finalPage) {
                const payload = {
                    amount: this.model.amount,
                    name: `${this.model.first_name} ${this.model.last_name}`,
                    email: this.model.email,
                    description: `${this.model.first_name} ${this.model.last_name} Single Charge`,
                    first_name: this.model.first_name,
                    last_name: this.model.last_name,
                    phone: this.model.phone,
                    address1: this.model.address1,
                    suburb: this.model.suburb,
                    state: this.model.state,
                    postcode:  this.model.postcode,
                    is_membership: 1,
                    recaptcha: this.model.recaptcha
                }
                this.createIntent(payload);
            }
            if(this.finalPage) this.finalPage = !this.finalPage;
        },
        onProcessPayment() {
            this.submit = true;
            this.$v.$touch();
            if (this.$v.$invalid || !this.paymentModel.cardValidate || this.model.agree === 0) {
                this.$helpers.focusErrorElement(this.$v.model, this.refsObj);
                return;
            }
            this.confirmPaymentIntent();
        },
        createIntent(payload) {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.refsObj);
                return true;
            }
            const self = this;
            this.intentLoading = true;
            this.createPaymentIntent(payload).then(data => {
                self.model.client_secret = data.client_secret;
                if(data.customer_id) self.model.membership_stripe_customer_id = data.customer_id;
                if(data.contact_id) self.model.contact_id = data.contact_id;
                self.paymentPage = !self.paymentPage;
                self.intentLoading = false;
            }).catch(err => self.intentLoading = false);
        },
        confirmPaymentIntent() {
            this.$store.commit("toggleAppLoading");
            const confirmPaymentOptions = {
                payment_method: {
                    card: this.paymentModel.cardNumber,
                    billing_details: { //sudebit
                        name: `${this.model.first_name} ${this.model.last_name}`,
                        email: this.model.email,
                    },
                },
                return_url: `${window.location.origin}/3d-secure-completed`, //card
                setup_future_usage: 'off_session'
            };

            this.stripe.confirmCardPayment(this.model.client_secret, confirmPaymentOptions)
            .then((result) => {
                this.$store.commit("toggleAppLoading");
                if(result.error) {
                    this.$store.commit("snackbar/SHOW_MESSAGE", {
                        text: result.error.message || 'Payment Failed',
                        color: "error",
                    });
                } else if(result.paymentIntent && result.paymentIntent.next_action) {
                    const self = this;
                    window.addEventListener('message', function(ev) {
                        if (ev.data === '3DS-authentication-complete') {
                            self.on3DSComplete();
                        }
                    }, false);
                } else {
                    this.model.payment_intent_id = result.paymentIntent.id;
                    this.respStripeToken(result);
                }
            }).catch(err => this.$store.commit("toggleAppLoading"));
        },
        on3DSComplete() {
            const self = this;
            // Check the PaymentIntent
            this.stripe.retrievePaymentIntent(self.model.client_secret)
                .then(function(result) {
                    if (result.error || (result.paymentIntent && result.paymentIntent.status === 'requires_payment_method')) {
                        self.$store.commit("snackbar/SHOW_MESSAGE", {
                            text: `Payment Failed!.`,
                            color: "error",
                        });
                    } else self.respStripeToken(result);
            });
        },
        respStripeToken(result) {
            if (result.error) {
                this.paymentModel.stripeErr = result.error.message;
                this.$store.commit("toggleAppLoading");
            } else {
                this.paymentModel.stripeErr = '';
                this.$store.commit("toggleAppLoading");
                this.donatePayment();
            }
        },
        donatePayment() {
            this.model.sf_record_type = this.themeRecordType;
            this.model.sf_campaign_id = this.themeModel.sf_campaign_id;
            this.model.is_membership = 1;
            const payload = this.model;
            delete payload.recaptcha;
            this.donatePaymentAmount(payload).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                const resData = response.data.data || null;
                if (resData && resData.status !== 0) {
                    this.$helpers.lsPush("curDonate", resData.id);
                    this.finalPage = true;
                }
            }).catch(() => { this.paymentPage = false });
        },
    }
};
</script>
